@use "../../scss/" as *;
.footer {
    position: relative;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.715);
    background-blend-mode: multiply;
    &__content {
        max-width: 1000px;
        &__logo {
            @include flex(center, center);
            margin-bottom: 3rem;
        }
        &__menus {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @include mobile {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        &__menu {
            @include flex(flex-start, flex-start);
            flex-direction: column;
            margin-top: 1rem;
            font-size: 1.5rem;
            font-weight: 600;
            &>*~* {
                margin-top: 1rem;
            }
        }
    }
}

.site-footer {
    border-top: 2px solid rgb(32, 32, 32);
    background-color: #000000;
    padding: 45px 20px;
    font-size: 1.17em;
    line-height: 24px;
    color: #ffffff;
    display: flexbox;
    text-align: center;
}

.site-footer hr {
    border-top: 2px solid rgb(32, 32, 32);
}

.site-footer hr.small {
    margin: 20px 0;
}

.site-footer h6 {
    color: dodgerblue;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    letter-spacing: 2px
}

.site-footer a {
    color: #737373;
}

.about {
    max-width: 50%;
    margin-left: 25%;
}

.site-footer a:hover {
    color: #3366cc;
    text-decoration: none;
}

.footer-links {
    padding-left: 0;
    list-style: none
}

.footer-links li {
    display: block
}

.footer-links a {
    color: #ffffff
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
    color: dodgerblue;
    text-decoration: none;
}

.footer-links.inline li {
    display: inline-block
}

.site-footer .social-icons {
    text-align: center;
}

.site-footer .social-icons a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 100%;
    background-color: #33353d
}

.copyright-text {
    float: right;
}

@media (max-width:991px) {
    .site-footer [class^=col-] {
        margin-bottom: 30px
    }
}

@media (max-width:767px) {
    .site-footer {
        padding-bottom: 0
    }
    .site-footer .copyright-text,
    .site-footer .social-icons {
        text-align: center
    }
}

.social-icons {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.social-icons li {
    display: inline-block;
    margin-bottom: 4px
}

.social-icons li.title {
    margin-right: 15px;
    text-transform: uppercase;
    color: #96a2b2;
    font-weight: 700;
    font-size: 13px
}

.social-icons a {
    background-color: #eceeef;
    color: #818a91;
    font-size: 16px;
    display: inline-block;
    line-height: 44px;
    width: 44px;
    height: 44px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    -webkit-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
    color: #fff;
    background-color: #29aafe
}

.social-icons.size-sm a {
    line-height: 34px;
    height: 34px;
    width: 34px;
    font-size: 14px
}

.social-icons a.facebook:hover {
    background-color: #3b5998
}

.social-icons a.twitter:hover {
    background-color: #00aced
}

.social-icons a.linkedin:hover {
    background-color: #007bb6
}

.social-icons a.dribbble:hover {
    background-color: #ea4c89
}

@media (max-width:767px) {
    .social-icons li.title {
        display: block;
        margin-right: 0;
        font-weight: 600
    }
}