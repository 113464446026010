@use "../../scss/" as *;
.movie-card {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160%;
    margin-bottom: 1rem;
    transition: 0.6s;
    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        transition: 0.3s ease;
    }
    @media (hover: hover) and (pointer: fine) {
        &:hover::before {
            opacity: 0.9;
        }
        &:hover .btn {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.movie-card:hover {
    background-color: rgba(0, 0, 0, 0.326);
    background-blend-mode: multiply;
    transition: 0.6s;
}