@use "../../scss/" as *;
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.logo {
    font-size: 1.8rem;
    font-weight: 600;
    font-family: 'Bebas Neue', cursive;
    @include flex(center,
    unset);
    img {
        margin-right: 10px;
        width: 130px;
        @include mobile {
            width: 90px;
            margin-right: 0;
        }
    }
}

.logo i {
    font-size: 2rem;
}

.logo img:hover {
    width: 135px;
    transition: 0.4s ease-in-out;
    @include mobile {
        width: 95px;
        margin-right: 0;
    }
}

.header {
    height: $header-height;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: height 0.3s ease, background-color 0.3s ease;
    &.shrink {
        height: 65px;
        background-color: rgba(0, 0, 0, 0.846);
    }
    &__wrap {
        @include flex(center,
        space-between);
        height: 100%;
        padding: 0 2rem;
        @include mobile {
            justify-content: center;
        }
    }
    &__nav {
        @include flex(center,
        unset);
        &>*~* {
            margin-left: 2rem;
        }
        li {
            padding: 5px 0;
            font-weight: 700;
            position: relative;
            font-size: 1.2rem;
            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                transition: width 0.5s ease;
                height: 2px;
                background-color: dodgerblue;
            }
            &.active::after,
            &:hover::after {
                width: 100%;
            }
        }
        @include mobile {
            position: fixed;
            bottom: 0;
            left: 0;
            height: 5rem;
            width: 100%;
            background-color: $body-bg;
            padding: 0 2rem;
            box-shadow: $box-shadow;
            @include flex(center,
            space-between);
            &>*~* {
                margin-left: 0;
            }
        }
    }
}