@use "../../scss/" as *;
button {
    cursor: pointer;
    font-family: 'Bebas Neue', cursive;
}

.btn,
.btn-outline {
    // background: $yellow;
    border: 2px solid dodgerblue;
    color: rgb(255, 255, 255);
    background-color: rgba(0, 0, 0, 0.49);
    padding: 0.5rem 1.9rem;
    font-size: 1rem;
    font-weight: 200;
    position: relative;
    border-radius: 20px;
    background: {
        image: linear-gradient(45deg, dodgerblue 50%, transparent 50%);
        position: 100%;
        size: 300%;
    }
    transition: background 300ms ease-in-out;
    &:hover {
        background-position: 0;
        transition: box-shadow 0.3s ease;
    }
}

.small {
    font-size: 1rem;
    padding: 4.5px 20px;
}

.search-box {
    position: relative;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 60px;
    background-color: #ffffff;
    line-height: 40px;
    padding: 10px;
    width: 100%;
    border-radius: 60px;
    max-width: 500px;
}

.search-input {
    background: #353535;
    color: #fff;
    outline: none;
    border: none;
    line-height: 40px;
    width: 100%;
    float: left;
    font-size: 14px;
    transition: 0.7s ease;
    font-family: sans-serif;
}

.search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: dodgerblue;
    padding: 12px;
    position: relative;
    border-radius: 50%;
    float: right;
    color: white;
    transition: 0.4s ease;
    top: -2px;
}

@media only screen and (max-device-width: 414px) {
    .search-btn {
        padding: 10px;
        top: -35px;
    }
}

.search-box:hover>.search-input {
    width: 240px;
    margin: 0px 8px;
}

.search-input:focus {
    width: 240px;
    margin: 0px 8px;
}

.search-box:hover>.search-btn {
    background: rgb(0, 0, 0);
}

.search-input:focus+.search-btn {
    background: #fff;
}

.bx-x::before {
    content: "\ebe9";
    background: rgb(255, 255, 255);
    color: dodgerblue;
    border-radius: 12px;
}